<template>
<div>
  <a href="static/_guanwang_leyou.tjyouzu.app.apk" ref="download" download="static/_guanwang_leyou.tjyouzu.app.apk"></a>
</div>
</template>

<script>
export default {
  name: "download",
  mounted() {
    this.$refs.download.click()
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
